import React from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import moment from 'moment';

import Logo from '../components/logo'

const DATA = require("../../redirect-data.json");
const BASE_URL = "https://www.sam-huang.info";
function jump(url) {
  window && (window.location.href = url);
}

import { graphql, useStaticQuery } from 'gatsby';

/****************************************/

const PINNED_ITEMS = [
  'RevtelIntro',
];

/****************************************/

const SEO = () => {
  const seo = {
    title: "[簡易轉址] 列表",
    image: BASE_URL + "/og.png",
    description: "",
    url: BASE_URL,
  };
  return (
    <Helmet
      title={seo.title}
      meta={[
        { name: "googlebot", content: "noindex" },
        { name: "robots", content: "noindex" },
        {
          name: `description`,
          content: seo.description,
        },
        {
          property: `image`,
          content: seo.image,
        },
        {
          property: `name`,
          content: seo.title,
        },
        {
          property: `og:title`,
          content: seo.title,
        },
        {
          property: `og:url`,
          content: seo.url,
        },
        {
          property: `og:site_name`,
          content: "Short URL | Sam Huang Info",
        },
        {
          property: `og:image`,
          content: seo.image,
        },
        {
          property: `og:description`,
          content: seo.description,
        },
        {
          property: "og:url",
          content: seo.url,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:image`,
          content: seo.image,
        },
        {
          name: `twitter:creator`,
          content: "Sam Huang,黃奕翔",
        },
        {
          name: `twitter:title`,
          content: seo.title,
        },
        {
          name: `twitter:description`,
          content: seo.description,
        },
        {
          property: "keywords",
          content:
            "Sam Huang, Sam, sailplaneTW, 黃奕翔, 賣飛機的, 忻旅科技, Revtel, RevtelTech, 奕果雲端, Eculture, EcultureTech, React, React Native, Inigma, Tel25, Tel-U, 加密鏈, 軟體開發, 軟體顧問, 新創營運, web3, 區塊鏈",
        },
      ]}
    />
  );
};

const PageMeta = ({ dataList = [] }) => {
  const data = useStaticQuery(graphql`
  query {
    site {
      siteMetadata {
        buildTime
      }
    }
  }
`);

  const _buildTime = data.site.siteMetadata.buildTime;
  const buildTime = moment.unix(parseInt(_buildTime / 1000))
    //.tz('Asia/Taipei')
    .format('YYYY/MM/DD HH:mm');

  return (
    <>
      <p>資料更新：{buildTime}</p>
      <p>縮址數量：{dataList.length} 組</p>
    </>
  );
}

const RedirectListPage = () => {
  const [inputFilter, setInputFilter] = React.useState("");
  const [moreInfo, showMoreInfo] = React.useState(false)

  React.useEffect(() => { }, []);

  let filteredData = (() => {
    let f = [], e = []
    DATA.forEach(item => {
      if (PINNED_ITEMS.indexOf(item[0]) !== -1) {
        e.push(item)
      } else {
        f.push(item)
      }


    })
    return f.concat(e)
  })()


  filteredData = filteredData.map((_, idx) => ({ idx, data: _ })).reverse()
    .filter((item) => {
      const data = JSON.stringify(item);
      if (inputFilter === "" || inputFilter == null) {
        return true;
      } else {

        let kws = inputFilter.split(' ').map(_ => _.toUpperCase())

        return kws.every(k => data.toUpperCase().indexOf(k) !== -1)

      }
    })

  return (
    <Wrapper>
      <SEO />

      <a
        className="action"
        href="/"
        target="_blank"
        style={{
          cursor: "pointer",
          flexDirection: "flow",
          justifyContent: "flex-start",
          alignItems: "center",
          textDecoration: "none",
          color: "black",
        }}
      >
        <div style={{ flex: 1 }} />
        <img src="/logo5.png" width={60 * 0.9} height={60 * 0.9} alt='Sam Huang 黃奕翔' style={{ borderRadius: '0%' }} />
        <div style={{ flex: 5 }} />
        <p
          style={{
            fontSize: "2rem",
            fontWeight: 500,
            textDecoration: "none",
            color: "black",

          }}
        >
          短網址
        </p>
        <div style={{ flex: 5 }} />
        <div style={{ width: 60 * 0.9, height: 2, backgroundColor: 'transparent' }}>&nbsp;</div>
        <div style={{ flex: 1 }} />
      </a>

      <div className="content">
        {/*<img src='/logo.png' width='60' style={{maxWidth:200, width:'50%'}} />*/}

        <img src="/Fidget-spinner.gif" width="80" style={{ marginBottom: 24 }} />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <input
            style={{ width: "80%", height: 40, paddingLeft: 10 }}
            value={inputFilter}
            placeholder="輸入過濾字串"
            onChange={(e) => {
              setInputFilter(e.target.value);
            }}
          />

          {!moreInfo && <div onClick={() => showMoreInfo(true)} style={{ cursor: 'pointer', backgroundColor: '#EEE2DE', borderRadius: 15, padding: 15, marginTop: 25, marginBottom: 25, }}>More Info ▼</div>}

          {moreInfo && <div onClick={() => showMoreInfo(false)} style={{ cursor: 'pointer', backgroundColor: '#EEE2DE', borderRadius: 15, padding: 15, marginTop: 25, marginBottom: 25, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

            <PageMeta dataList={filteredData} />




            <h4
              style={{
                color: "#850000",
                marginBottom: -10,
                cursor: "copy",
                fontWeight: "bold",
              }}
              onClick={() =>
                window &&
                window.navigator.clipboard.writeText(
                  "https://l.revtel.app/s/"
                )
              }
            >
              複製(中轉)前綴:<br /> https://l.revtel.app/s/ ⎘
            </h4>
            <h4
              style={{
                color: "#850000",
                marginBottom: -10,
                cursor: "copy",
                fontWeight: "bold",
              }}
              onClick={() =>
                window &&
                window.navigator.clipboard.writeText(
                  "https://l.revtel.app/j/"
                )
              }
            >
              複製(立即)前綴:<br /> https://l.revtel.app/j/ ⎘
            </h4>
            <br />
            <h4
              style={{
                color: "#3C2A21",
                marginBottom: -10,
                cursor: "copy",
                fontWeight: "bold",
              }}
              onClick={() =>
                window &&
                window.navigator.clipboard.writeText(
                  "https://www.sam-huang.info/s/"
                )
              }
            >
              複製(中轉)前綴:<br /> https://www.sam-huang.info/s/ ⎘
            </h4>
            <h4
              style={{
                color: "#3C2A21",
                marginBottom: -10,
                cursor: "copy",
                fontWeight: "bold",
              }}
              onClick={() =>
                window &&
                window.navigator.clipboard.writeText(
                  "https://www.sam-huang.info/j/"
                )
              }
            >
              複製(立即)前綴:<br /> https://www.sam-huang.info/j/ ⎘
            </h4>

            <br />
            ▲

          </div>}

          <br />
          <hr style={{ width: "70%" }} />
          <br />

          {filteredData
            .map((k, idx) => {
              return <LINKComp key={idx} k={k} idx={idx} />;
            })}
        </div>
      </div>
      <a
        className="action"
        href="/"
        target="_blank"
        style={{
          cursor: "pointer",
          flexDirection: "flow",
          justifyContent: "center",
          alignItems: "center",
          textDecoration: "none",
          color: "black",
        }}
      >
        <img src="/left-arrow.png" width="36" />
      </a>
    </Wrapper>
  );
};

function shortenText(f, e, src) {
  return src.slice(0, f) + ' ... ' + src.slice(-e)
}

function splitLineToChunk(s = "", func) {
  return s.match(/.{1,30}/g).map(func);
}

class LINKComp extends React.Component {
  state = {
    expand: false,
  };
  render() {
    const [short, target, note, seo = {}] = this.props.k.data;


    const containerStyle = {
      backgroundColor: [
        '#F3EEEA', '#EBE3D5', '#F7EFE5',
      ][this.props.idx % 3],
      borderRadius: 15,
      padding: 15,
      width: '50%',
      minWidth: 300,
      maxWidth: 800,
      cursor: 'pointer'
    }

    if (!this.state.expand) {
      return (

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: 'center',
            marginBottom: 50,

            ...containerStyle,

          }}
          onClick={() => this.setState({ expand: !this.state.expand })}
        >

          {PINNED_ITEMS.indexOf(short) !== -1 && <img src='/images/star.png' width='32' height='32' />}

          {note && note !== "" && (
            <h3 style={{ color: "#009EFF", marginBottom: -10 }}>{note}</h3>
          )}

          <h4
            style={{
              color: "#C58940",
              marginBottom: -10,
              cursor: "copy",
              fontWeight: "bold",
            }}
            onClick={(e) => {
              e.stopPropagation();
              window &&
                window.navigator.clipboard.writeText(
                  "https://l.revtel.app/j/" + short
                )
            }}
          >
            立即: /j/{short} (revtel) ⎘
          </h4>
          <br />
          <div
            style={{ cursor: "pointer", padding: '2px 50px 2px 50px' }}
            onClick={() => this.setState({ expand: true })}
          >
            ▼
          </div>

          {splitLineToChunk(shortenText(15, 7, target), (c) => (
            <p
              key={c}
              style={{
                marginBottom: -5,
                color: "#8B7E74",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={(e) => { e.stopPropagation(); window.open(target, "_blank") }}
            >
              {c}
            </p>
          ))}
        </div>

      );
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: 50,

          ...containerStyle
        }}
        onClick={() => this.setState({ expand: !this.state.expand })}
      >
        <p style={{ color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 45, height: 45, borderRadius: '50%', backgroundColor: '#7ED7C1' }}>{this.props.k.idx + 1}</p>
        {note && note !== "" && (
          <h3 style={{ color: "#009EFF", marginBottom: -10 }}>{note}</h3>
        )}
        <h4
          style={{
            color: "#227C70",
            marginBottom: -10,
            cursor: "copy",
            fontWeight: "bold",
          }}
          onClick={(e) => {
            e.stopPropagation();
            window &&
              window.navigator.clipboard.writeText(
                "https://l.revtel.app/s/" + short
              )
          }}
        >
          中轉: /s/{short} (revtel) ⎘
        </h4>
        <h4
          style={{
            color: "#C58940",
            marginBottom: -10,
            cursor: "copy",
            fontWeight: "bold",
          }}
          onClick={(e) => {
            e.stopPropagation();
            window &&
              window.navigator.clipboard.writeText(
                "https://l.revtel.app/j/" + short
              )
          }}
        >
          立即: /j/{short} (revtel) ⎘
        </h4>
        <h4
          style={{
            color: "#227C70",
            marginBottom: -10,
            cursor: "copy",
            fontWeight: "bold",
          }}
          onClick={(e) => {
            e.stopPropagation();
            window &&
              window.navigator.clipboard.writeText(
                "https://www.sam-huang.info/s/" + short
              )
          }}
        >
          中轉: /s/{short} (sam) ⎘
        </h4>
        <h4
          style={{
            color: "#C58940",
            marginBottom: -10,
            cursor: "copy",
            fontWeight: "bold",
          }}
          onClick={(e) => {
            e.stopPropagation();
            window &&
              window.navigator.clipboard.writeText(
                "https://www.sam-huang.info/j/" + short
              )
          }}
        >
          立即: /j/{short} (sam) ⎘
        </h4>
        <br />
        {seo.image && <img src={seo.image} style={{ width: '80%' }} />}
        <br />
        <div
          style={{ cursor: "pointer", padding: '2px 50px 2px 50px' }}
          onClick={() => this.setState({ expand: false })}
        >
          ▲
        </div>
        {splitLineToChunk(target, (c) => (
          <p
            key={c}
            style={{
              marginBottom: -5,
              color: "#8B7E74",
              textDecoration: "underline",
              cursor: "pointer",

            }}
            onClick={(e) => { e.stopPropagation();; window.open(target, "_blank") }}
          >
            {c}
          </p>
        ))}
      </div>
    );
  }
}

const Wrapper = styled.div`
    min-height: 98vh;


    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > .action {
        display: flex;
        flex-direction: row;
        //padding: 15px;
        width:100%;
        height:64px;
        background-color: #EEEEEE;
    }

    & > .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex:1;
        width:100%;
        padding-top:50px;
        padding-bottom:50px;
        //background-color: #FEFCF3;
    }
`;

export default RedirectListPage;
